function isDesktop() {
  return window.innerWidth > 768;
}

function handleClick(event) {
  if (isDesktop()) {
    event.preventDefault();
    const button = document.getElementById("contactButton");
    button.innerHTML =
      '<span class="point point--accent">+48 792 338 668</span>';
    button.removeAttribute("href");
  }
}

document.getElementById("contactButton").addEventListener("click", handleClick);
